<template>
  <div v-if="getKycData" class="kycStatus">
    <div
      class="widgetContainer widgetContainer--center widgetContainer--full-screen approved"
      v-if="getKycData.status === 'approved'">
      <div class="widgetContainer__body">
        <LottieAnimation animation-name="success" reference="kyc-status-success" :loop="true" />
        
        <p class="title">
          {{ $t('kycStatus_approved_title') }}
        </p>
        <p class="description">
          {{
            $t(
              ($businessChecking === true && $personalChecking === true) ||
                ($businessChecking === false && $personalChecking === true)
                ? 'kycStatus_approved_description_personalChecking'
                : 'kycStatus_approved_description'
            )
          }}
        </p>
      </div>
      <div class="widgetContainer__footer--fixed">
        <el-button
          v-if="$businessChecking === true"
          type="primary"
          class="welcome__get-started el-button__brand brand"
          @click="proceedToBusiness">
          {{
            $t(
              $businessChecking === true && $personalChecking === true
                ? 'done'
                : 'kycStatus_approved_buttonTitle'
            )
          }}
        </el-button>
        <el-button
          v-else-if="$personalChecking === true"
          type="primary"
          class="welcome__get-started el-button__brand brand"
          @click="accountSetup">
          {{ $t('done') }}
        </el-button>
      </div>
    </div>

    <div
      class="
        widgetContainer widgetContainer--center widgetContainer--scrollable widgetContainer--full-screen
        declined
      
      "
      v-else-if="getKycData.status === 'declined'">
      <div class="widgetContainer__body">
        <LottieAnimation animation-name="failure" reference="kyc-status-failure-1" :loop="true" />
        <p class="title">
          {{ $t('kycStatus_declined_title') }}
        </p>
        <p class="description">
          {{ $t('kycStatus_declined_description') }}
        </p>
        <div class="widgetContainer__footer--fixed">
          <el-button type="primary" class="welcome__get-started el-button__brand brand">
            {{ $t('kycStatus_declined_buttonTitle') }}
          </el-button>
        </div>
      </div>
    </div>

    <div
      class="widgetContainer widgetContainer--center widgetContainer--full-screen verifying"
      v-else-if="getKycData.status === 'submitted' && isLoading">
      <LottieAnimation animation-name="searching" reference="kyc-status-review-1" :loop="true" />
      <p class="title">
        {{ $t('kycStatus_waiting_title') }}
      </p>
    </div>

    <div
      class="
        widgetContainer widgetContainer--center widgetContainer--scrollable widgetContainer--full-screen
        review
      
      "
      v-else>
      <div class="widgetContainer__body">
        <LottieAnimation animation-name="searching" reference="kyc-status-review-2" :loop="true" />
        <p class="title">
          {{ $t('kycStatus_inReview_title') }}
        </p>
        <p class="description">
          {{ $t('kycStatus_inReview_description') }}
        </p>
        <div class="widgetContainer__footer--fixed">
          <el-button
            type="primary"
            class="welcome__get-started el-button__brand brand"
            @click="openHelp">
            {{ $t('kycStatus_inReview_buttonTitle') }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  name: 'KycStatus',
  components: {
    LottieAnimation
  },
  created() {
    /** @type {import('@/modules/kyc/store/state').kycState['kycData']} */
    const kycData = this.getKycData;
    if (kycData === null || kycData.id === null) {
      this.getPersonKYC();
    } else {
      this.accountCreationForPersonalChecking();
    }
  },
  data() {
    return {
      isLoading: true,
      retryCount: 0
    };
  },
  computed: {
    ...mapGetters('kyc', ['getKycData']),
    ...mapGetters('kyb', [
      'getBusinessList',
      'getBussinessDetailsFetchedStatus'
    ]),
    ...mapGetters('user', ['getPersonDetails'])
  },
  methods: {
    ...mapActions('kyc', ['fetchKyc']),
    ...mapActions('kyb', ['listAllBusiness']),
    ...mapMutations('kyb', ['setSelectedBusiness']),
    ...mapMutations('account', ['updateCreateAccountData']),
    getPersonKYC(showLoader = true) {
      const loader = showLoader ? this.showLoader() : {};
      /** @type {import('@/modules/user/store/state').userState['personDetails']} */
      const person = this.getPersonDetails;
      if (person && person.id) {
        this.fetchKyc(person.id)
          .then((data) => {
            /** @type {import('@m/kyc/store/state').kycState['kycData']} */
            const kycData = data;
            if (kycData.status === 'submitted') {
              if (this.retryCount < 3) {
                this.isLoading = true;
                this.retryCount++;
                setTimeout(() => {
                  this.getPersonKYC();
                }, 5000);
              } else {
                this.isLoading = false;
                setTimeout(() => {
                  this.getPersonKYC(false);
                }, 5000);
              }
            } else {
              this.accountCreationForPersonalChecking();
            }
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            if (showLoader && loader.close) loader.close();
          });
      }
    },
    accountCreationForPersonalChecking() {
      if (this.getKycData && this.getKycData.status === 'approved') {
        this.isLoading = false;
      } else {
        this.isLoading = false;
        setTimeout(() => {
          this.getPersonKYC(false);
        }, 5000);
      }
    },
    proceedToBusiness() {

      window.analytics && window.analytics.track('proceedBusiness');

      if (this.getBussinessDetailsFetchedStatus === 'fetched') {
        /** @type {import('@m/kyb/store/state').kybState['bussinessDetails']} */
        const businessList = this.getBusinessList;
        if (businessList.length === 0) {
          if (
            this.$businessChecking === true &&
            this.$personalChecking === true
          ) {
            this.$router.push('/kyc/accountType');
          } else {
            //create business
            this.$router.push('/kyb/businessDetails');
          }
        } else {
          this.setSelectedBusiness(businessList[0]);
        }
      } else {
        const loader = this.showLoader();
        this.$store
          .dispatch('kyb/listAllBusiness')
          .then((r) => {
            if (r.total === 0) {
              if (
                this.$businessChecking === true &&
                this.$personalChecking === true
              ) {
                this.$router.push('/kyc/accountType');
              } else {
                //create business
                this.$router.push('/kyb/businessDetails');
              }
            } else {
              /** @type {import('@m/kyb/store/state').kybState['bussinessDetails']} */
              const businessList = r.data;
              this.setSelectedBusiness(businessList[0]);
            }
          })
          .catch((e) => {
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      }
    },
    accountSetup() {
      this.updateCreateAccountData({
        businessId: null,
        type: 'personalChecking'
      });
      this.$router.push('/account/setup');
    },
    /**
     * @typedef {import('@m/kyb/store/state').kybState['selectedBusinessType']} business
     * @param {business} business
     */
    kybRedirect(business) {
      if (business && business.kyb && business.kyb.status) {
        switch (business.kyb.status) {
        case 'approved':
          break;
        case 'notStarted':
          this.$router.push('/kyb/businessDetails');
          break;
        }
      }
    },
  }
};
</script>

<style lang="scss">
.kycStatus {
  .welcome__get-started {
    min-width: 100%;
    min-height: 40px;
  }

  .review,
  .verifying,
  .approved,
  .declined {
    text-align: center;
    padding-top: 50px;

    .widgetContainer__body {
      display: flex;
      flex-direction: column;
    }

    .icon {
      font-size: 75px;
      margin-bottom: 36px;

      .path1 {
        &:before {
          color: var(--color-green);
        }
      }
    }

    .title,
    .description {
      padding-left: 40px;
      padding-right: 40px;
    }

    .title {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 16px;
    }

    .description {
      color: rgba(#3c3c43, 0.6);
      font-size: 14px;
      line-height: 20px;
    }

    .el-button {
      margin-top: auto;
    }
  }

  .declined {
    .icon {
      .path1 {
        &:before {
          color: var(--color-red);
        }
      }
    }
  }

  .verifying {
    .icon {
      .path1 {
        &:before {
          color: var(--orange2);
        }
      }
    }
  }

  .review {
    .icon {
      .path1 {
        &:before {
          color: var(--orange2);
        }
      }
    }
  }
}
</style>
